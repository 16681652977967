import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import loadable from "@loadable/component";
import paymentMethods from "../../../assets/images/payment-methods.png";
import logoFooter from "../../../assets/images/logo-footer.svg";
import { EMOJI, RELOAD_DOCUMENT_REQUIRED, STATUS } from "../../../shared/enums";
import { selectKlaviyoData, selectKlaviyoError, selectKlaviyoStatus } from "../../features/klaviyo/klaviyoSlice";

const LazyImg = loadable(() => import("../common/LazyImg"));
const KlaviyoSubscribeForm = loadable(() =>
  import("./KlaviyoSubscribeForm")
);

/**
 * @param pathname
 * @returns {JSX.Element}
 * @constructor
 */

const FooterRefresh = ({ pathname }) => {
  const klaviyoData = useSelector(selectKlaviyoData);
  const klaviyoError = useSelector(selectKlaviyoError);
  const klaviyoStatus = useSelector(selectKlaviyoStatus);

  return (
    <footer className="footer-refresh">
      <div className="container">
        <nav className="footer-nav">
          <div className="footer-refresh__logo">
            <a href="/">
              <span className="screen-reader-text">Mila branding</span>
              <LazyImg src={logoFooter} alt="Mila" />
            </a>
          </div>
          <div className="footer-nav__grid">
            <div className="footer-nav__column">
              <h3 className="footer-nav__title">Products</h3>
              <ul className="footer-nav__menu">
                <li>
                  <Link to="/">Mila Air</Link>
                </li>
                <li>
                  <a href="https://airmini.milacares.com/">Mila Air Mini</a>
                </li>
              </ul>
            </div>
            <div className="footer-nav__column">
              <h3 className="footer-nav__title">Filters</h3>
              <ul className="footer-nav__menu">
                <li>
                  <a href={MILA_MY_ACCOUNT_HOST}>My Mila Account</a>
                </li>
                <li>
                  <a href="https://shop.milacares.com/filters">Buy Filters</a>
                </li>
              </ul>
            </div>

            <div className="footer-nav__column">
              <h3 className="footer-nav__title">Technology</h3>
              <ul className="footer-nav__menu">
                <li>
                  {RELOAD_DOCUMENT_REQUIRED.includes(pathname) ? (
                    <a href="/performance">Performance</a>
                  ) : (
                    <Link to="/performance">Performance</Link>
                  )}
                </li>
                <li>
                  <Link to="/app">Application</Link>
                </li>
              </ul>
            </div>

            <div className="footer-nav__column">
              <h3 className="footer-nav__title">Support</h3>
              <ul className="footer-nav__menu">
                <li>
                  <Link to="/support#faq">FAQs </Link>
                </li>
                <li>
                  <a href="mailto:support@milacares.com">Get Help </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://milacares.com/lp/warranty"
                    rel="noreferrer"
                  >
                    Warranty
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-nav__column">
              <h3 className="footer-nav__title">Company</h3>
              <ul className="footer-nav__menu">
                <li>
                  {RELOAD_DOCUMENT_REQUIRED.includes(pathname) ? (
                    <a href="/our-story">Our Story</a>
                  ) : (
                    <Link to="/our-story">Our Story</Link>
                  )}
                </li>
                <li>
                  {RELOAD_DOCUMENT_REQUIRED.includes(pathname) ? (
                    <a href="/careers">Careers</a>
                  ) : (
                    <Link to="/careers">Careers</Link>
                  )}
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://docs.milacares.com/legal/terms.pdf"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://docs.milacares.com/legal/privacy.pdf"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  {RELOAD_DOCUMENT_REQUIRED.includes(pathname) ? (
                    <a href="/accessibility-policy/">Accessibility Policy</a>
                  ) : (
                    <Link to="/accessibility-policy/">
                      Accessibility Policy
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="footer-refresh__secondary">
          <div className="footer-refresh__newsletter">
            <div className="footer-refresh__newsletter-inner">
              <p className="footer-refresh__newsletter-info">
                <strong>Let’s connect</strong>
                <span>
                  Don’t miss the latest on Mila news and offers.
                  {EMOJI.ONCOMING_FIST}
                </span>
              </p>
              <div
                id="mc_embed_signup"
                className="footer-refresh__newsletter-form"
              >
                <KlaviyoSubscribeForm buttonText="Subscribe" />
                {
                  klaviyoStatus === STATUS.SUCCEEDED && (
                    <p className={'footer-refresh__newsletter-message ' + klaviyoStatus}>
                      {klaviyoData?.title}
                    </p>)
                }
                {
                  klaviyoStatus === STATUS.FAILED && (
                    <p className={'footer-refresh__newsletter-message ' + klaviyoStatus}>
                      {klaviyoError?.error}
                    </p>)
                }
              </div>
            </div>
            <ul className="footer-refresh__social-links">
              <li>
                <a
                  href="https://facebook.com/milacaresquad"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="icon icon-facebook">
                    <use xlinkHref="#icon-facebook" />
                  </svg>
                  <span>facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/milacaresquad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg className="icon icon-instagram">
                    <use xlinkHref="#icon-instagram" />
                  </svg>
                  <span>instagram</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-refresh__payment">
            <p className="copyright">
              &copy; {new Date().getFullYear()} All Rights Reserved
            </p>
            <LazyImg
              className="footer-refresh__payment-image"
              src={paymentMethods}
              alt="payment methods"
            />
          </div>
        </div>
      </div>
      <button
        id="accessibilityWidget"
        className="accessibility-widget"
        tabIndex="0"
      >
        <span className="screen-reader-text">Click for Accessibility</span>
        <svg className="icon icon-accessibility">
          <use xlinkHref="#icon-accessibility" />
        </svg>
      </button>
    </footer>
  );
};

export default FooterRefresh;
