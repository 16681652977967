const filterPricesStatic = {
  HW: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
  RP: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
  MB: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
  BB: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
  BS: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
  CC: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
  OR: {
    US: { base: 349, sales: 297 },
    CA: { base: 470, sales: 400},
  },
};

export default filterPricesStatic;
