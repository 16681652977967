import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { useIsMobile } from "../../hooks";
import loadable from "@loadable/component";
const VideoPlayer = loadable(() => import("../common/VideoPlayer"));
import { videoItems } from "../../static-data/LandingRefresh/airbliviousVideoStatic";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const VideoItem = ({ videoSrc, poster, quote }) => {
  return (
    <div className="airblivious-section__item">
      <div className="airblivious-section__media">
        <VideoPlayer
          videoSrc={videoSrc}
          poster={poster}
          className="airblivious-section__video"
        />
      </div>
      <div className="airblivious-section__quote">
        <p>“{quote}”</p>
      </div>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <button type="button" className="slick-arrow slick-next" onClick={onClick}>
    <span className="screen-reader-text">Next</span>
    <svg className="icon icon-circle-right-arrow">
      <use xlinkHref="#icon-circle-right-arrow"></use>
    </svg>
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button type="button" className="slick-arrow slick-prev" onClick={onClick}>
    <span className="screen-reader-text">Previous</span>
    <svg className="icon icon-circle-left-arrow">
      <use xlinkHref="#icon-circle-left-arrow"></use>
    </svg>
  </button>
);
const AirbliviousVideo = () => {
  const isMobile = useIsMobile(769);
  const sliderRef = useRef(null);
  const [videoInit, setVideoInit] = useState(false);
  const [fullyLoaded, setFullyLoaded] = useState(false);

  useEffect(() => {
    const videoElements = document.querySelectorAll(
      ".airblivious-section__video"
    );

    const promises = Array.from(videoElements).map((video) => {
      return new Promise((resolve) => {
        video.onloadeddata = resolve;
      });
    });

    Promise.all(promises).then(() => {
      setVideoInit(true);
    });
  }, []);

  useEffect(() => {
    const videoElements = document.querySelectorAll(
      ".airblivious-section__video"
    );

    const promises = Array.from(videoElements).map((video) => {
      return new Promise((resolve) => {
        if (video.readyState >= 3) {
          video.play();
          resolve();
        } else {
          video.onloadeddata = () => {
            video.play();
            resolve();
          };
        }
      });
    });

    Promise.all(promises).then(() => {
      setVideoInit(true);
    });
  }, []);

  const hoverHandler = () => {
    if (isMobile) return;
    sliderRef.current.slickPlay();
  };
  const hoverOutHandler = () => {
    if (isMobile) return;
    sliderRef.current.slickPause();
  };

  const settings = {
    speed: 5000,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    variableWidth: true,
    centerMode: true,
    centerPadding: 0,
    autoplay: false,
    autoplaySpeed: 0,
    pauseOnHover: false,
    pauseOnFocus: false,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 2160,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1025,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          centerMode: true,
          autoplay: false,
          centerPadding: "60px",
          autoplaySpeed: 4000,
          speed: 500,
          initialSlide: 1,
          arrows: true,
          cssEase: "ease",
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
    ],
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFullyLoaded(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="airblivious-section">
      <div className="container">
        <div className="airblivious-section__header">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
            className="airblivious-section__title"
          >
            Air·blivious
          </motion.h2>
          <motion.h3
            className="airblivious-section__sub-title"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.02 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            [ehr-<strong>bliv</strong>-ee-uhs] <strong>adjective</strong>
          </motion.h3>
          <motion.div
            className="airblivious-section___description"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.01 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            <p>
              Lacking knowledge or awareness of the air quality one is
              breathing.
            </p>
          </motion.div>
        </div>
        {videoInit && (
          <motion.div
            className="airblivious-section__body"
            initial={{ opacity: 0 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.5 }}
            variants={{
              visible: { opacity: fullyLoaded ? 1 : 0 },
              hidden: { opacity: 0 },
            }}
            onMouseEnter={hoverHandler}
            onMouseLeave={hoverOutHandler}
          >
            <Slider
              className={`airblivious-section__slider ${
                !fullyLoaded ? "no-transition" : ""
              }`}
              {...settings}
              ref={sliderRef}
            >
              {videoItems.map((item, index) => (
                <VideoItem
                  key={item.videoSrc}
                  videoSrc={item.videoSrc}
                  poster={item.poster}
                  quote={item.quote}
                />
              ))}
            </Slider>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default AirbliviousVideo;
