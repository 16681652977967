/* eslint-disable react/jsx-filename-extension */
import React from "react";

const airMiniAppChangeLogStatic = [
  {
    version: "2.3.0",
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>What’s New:</strong>
        </p>
        <ul>
          <li>
            <strong>Holiday Easter Eggs 😏:</strong> Keep an eye out for some
            seasonal cheer and festive surprises
          </li>
          <li>
            <strong> Brightness Tweaks:</strong> Daytime brightness is now set
            to 100%, while nighttime dims to a cozy 30%.
          </li>
          <li>
            <strong>Boot-Up Chime Update:</strong> Mila stays silent during
            Sleep or Whitenoise Mode, ensuring no disruptions during quiet
            hours.
          </li>
          <li>
            <strong>Display Improvements:</strong> Experience more responsive
            touch interactions
          </li>
        </ul>
      </>
    ),
  },
  {
    version: "2.2.5",
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements:</strong>
        </p>
        <ul>
          <li>
            Enhanced touch sensitivity and input processing to make interactions
            smoother, more responsive, and accurate.
          </li>
        </ul>
      </>
    ),
  },
];

export default airMiniAppChangeLogStatic;
