import React, { useState, useRef, useEffect } from "react";
import loadable from "@loadable/component";
import { motion, AnimatePresence } from "framer-motion";
const ImageLoader = loadable(() => import("../common/ImageLoader"));
import { useIsMobile } from "../../hooks";

import milaChart01 from "assets/images/landingAir/mila-chart/chart-01.svg";
import milaChart01Sm from "assets/images/landingAir/mila-chart/chart-sm-01.svg";
import milaChart02 from "assets/images/landingAir/mila-chart/chart-02.svg";
import milaChart02Sm from "assets/images/landingAir/mila-chart/chart-sm-02.svg";
import milaChart03 from "assets/images/landingAir/mila-chart/chart-03.svg";
import milaChart03Sm from "assets/images/landingAir/mila-chart/chart-sm-03.svg";
import milaChart04 from "assets/images/landingAir/mila-chart/chart-04.svg";
import milaChart04Sm from "assets/images/landingAir/mila-chart/chart-sm-04.svg";
import milaChart05 from "assets/images/landingAir/mila-chart/chart-05.svg";
import milaChart05Sm from "assets/images/landingAir/mila-chart/chart-sm-05.svg";
import milaChart06 from "assets/images/landingAir/mila-chart/chart-06.svg";
import milaChart06Sm from "assets/images/landingAir/mila-chart/chart-sm-06.svg";
/**
 * @returns {JSX.Element}
 * @constructor
 */

const stats = [
  {
    id: "pm2",
    mainTitle: (
      <>
        She filters 99.9% of smog,
        <br /> because pollution has no place in your home.
      </>
    ),
    description:
      "While there are no direct tests that measure the filtration efficacy of PM2.5, PM2.5 (smog) ranges in particulate size from 0.1 to 2.5µm, which is the same range as smoke and dust. ",
    title: "🚕💨 PM2.5",
    image: milaChart01,
    imageSm: milaChart01Sm,
  },
  {
    id: "smoke",
    mainTitle: (
      <>
        She filters 99.9% of smoke,
        <br /> so you don't have to worry when the world's on fire.
      </>
    ),
    description: (
      <>
        Conducted in a 1,007 ft<sup>3</sup> chamber on Mila Air and the Basic
        Breather filter with tests performed in accordance with ANSI/AHAM
        AC-1-2020 entitled "Association of Home Appliance Manufacturers Method
        for Measuring Performance of Portable Household Electric Room Air
        Cleaners."
      </>
    ),
    title: "🔥💨 Smoke",
    image: milaChart02,
    imageSm: milaChart02Sm,
  },
  {
    id: "dust",
    mainTitle: (
      <>
        She filters 99.9% of dust,
        <br /> saving you from unnecessary sneezes.
      </>
    ),
    description: (
      <>
        Conducted in a 1,007 ft<sup>3</sup> chamber on Mila Air and the Basic
        Breather filter with tests performed in accordance with ANSI/AHAM
        AC-1-2020 entitled "Association of Home Appliance Manufacturers Method
        for Measuring Performance of Portable Household Electric Room Air
        Cleaners."
      </>
    ),
    title: "🧹💨 Dust",
    image: milaChart03,
    imageSm: milaChart03Sm,
  },
  {
    id: "dander",
    mainTitle: (
      <>
        She filters 99.9% of pet dander,
        <br /> so you and Fido can hang out without the hives.
      </>
    ),
    description: (
      <>
        Conducted in a 1,007 ft<sup>3</sup> chamber on Mila Air and the Critter
        Cuddler filter with tests performed in accordance with ANSI/AHAM
        AC-1-2020 entitled "Association of Home Appliance Manufacturers Method
        for Measuring Performance of Portable Household Electric Room Air
        Cleaners."',
      </>
    ),
    title: (
      <>
        🐶🐱&nbsp;<span>Pet&nbsp;</span>Dander
      </>
    ),
    image: milaChart04,
    imageSm: milaChart04Sm,
  },
  {
    id: "pollen",
    mainTitle: (
      <>
        She filters 99.9% of pollen,
        <br /> so you can make sure the outdoors stay out.
      </>
    ),
    description: (
      <>
        Conducted in a 1,007 ft<sup>3</sup> chamber on Mila Air and the Big
        Sneeze filter with tests performed in accordance with ANSI/AHAM
        AC-1-2020 entitled "Association of Home Appliance Manufacturers Method
        for Measuring Performance of Portable Household Electric Room Air
        Cleaners."
      </>
    ),
    title: "🌼🤧 Pollen",
    image: milaChart05,
    imageSm: milaChart05Sm,
  },
  {
    id: "virus",
    mainTitle: (
      <>
        She filters 98.9% of viruses,
        <br /> helping to keep the sniffles out of your space.
      </>
    ),
    description: (
      <>
        Conducted in a 1,007 ft<sup>3</sup> chamber on Mila Air and the
        Overreactor filter with tests performed in accordance with ANSI/AHAM
        AC-1-2020 entitled "Association of Home Appliance Manufacturers Method
        for Measuring Performance of Portable Household Electric Room Air
        Cleaners."
      </>
    ),
    title: "😷🦠 Virus",
    image: milaChart06,
    imageSm: milaChart06Sm,
  },
];

const MilaChart = () => {
  const isMobile = useIsMobile(769);
  const containerRef = useRef(null);
  const [activeFilter, setActiveFilter] = useState(() =>
    isMobile ? "smoke" : "pm2"
  );

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const activeStats = stats.find((stat) => stat.id === activeFilter);
  useEffect(() => {
    setActiveFilter(isMobile ? "smoke" : "pm2");
  }, [isMobile]);
  return (
    <section className="mila-chart">
      <div className="container">
        <div className="mila-chart__card">
          <div className="mila-chart__header">
            <h2 className="mila-chart__title">{activeStats.mainTitle}</h2>
            {isMobile && (
              <ChartInfo isMobile={isMobile} className="for-small-screen" />
            )}
          </div>
          <div ref={containerRef} className="mila-chart__body">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeFilter}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{ position: "absolute" }}
              >
                <ImageLoader
                  className="mila-chart__image img-relative"
                  placeholderAspectRatio={993 / 435}
                  svgSmUrl={activeStats.imageSm}
                  svgUrl={activeStats.image}
                  alt={`${activeFilter} chart`}
                />
              </motion.div>
            </AnimatePresence>
          </div>
          <div className="mila-chart__footer">
            <div className="mila-chart__info">
              <p className="mila-chart__info-text">{activeStats.description}</p>
              {!isMobile && (
                <ChartInfo isMobile={isMobile} className="for-large-screen" />
              )}
            </div>
            <div className="mila-chart__filter">
              <div className="mila-chart__filter-center">
                <ul className="mila-chart__filter-list">
                  {stats.map((stat) => (
                    <li key={stat.id}>
                      <button
                        type="button"
                        className={`button button--white button--shadow-dark button--lg ${
                          activeFilter === stat.id ? " active" : ""
                        }`}
                        onClick={() => handleFilterClick(stat.id)}
                      >
                        {stat.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mila-chart__footer-bottom footer-content">
          <h4>Fun Fact 🤓</h4>
          <p>
            Milas detected potentially dangerous carbon monoxide (CO) levels for 91 households in
            the past year.
          </p>
          {/* <a
            href="/performance"
            className="button button--white button--lg button--shadow-dark"
          >
            Dive into Mila's Performance
          </a> */}
        </div>
      </div>
    </section>
  );
};

const ChartInfo = ({ isMobile, className = "" }) => {
  return (
    <div className={`mila-chart__info-scheme ${className}`}>
      <p>
        {isMobile ? (
          <svg
            width="19"
            height="5"
            viewBox="0 0 19 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.91321 2.10547L18.5859 2.10547"
              stroke="url(#paint0_linear_23877_2251)"
              strokeWidth="4"
            />
            <defs>
              <linearGradient
                id="paint0_linear_23877_2251"
                x1="0.986866"
                y1="0.401364"
                x2="18.5893"
                y2="0.598934"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.0316992" stopColor="#F03358" />
                <stop offset="0.54" stopColor="#FFB216" />
                <stop offset="0.965" stopColor="#1CE4D5" />
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <svg
            width="63"
            height="7"
            viewBox="0 0 63 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.17954e-06 3.53125L63 3.53125"
              stroke="url(#paint0_linear_23657_2128)"
              strokeWidth="6"
            />
            <defs>
              <linearGradient
                id="paint0_linear_23657_2128"
                x1="0.262569"
                y1="1.82715"
                x2="62.9197"
                y2="4.33414"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.0316992" stopColor="#F03358" />
                <stop offset="0.54" stopColor="#FFB216" />
                <stop offset="0.965" stopColor="#1CE4D5" />
              </linearGradient>
            </defs>
          </svg>
        )}
        Mila removal efficiency %
      </p>
      <p>
        {isMobile ? (
          <svg
            width="19"
            height="3"
            viewBox="0 0 19 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.803835 1.10547L18.4766 1.10547"
              stroke="url(#paint0_linear_23877_2254)"
              strokeWidth="2"
              strokeDasharray="3 3"
            />
            <defs>
              <linearGradient
                id="paint0_linear_23877_2254"
                x1="18.3203"
                y1="1.35156"
                x2="5.71488"
                y2="-4.14248"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#666666" />
                <stop offset="1" stopColor="#D7D7D7" />
              </linearGradient>
            </defs>
          </svg>
        ) : (
          <svg
            width="63"
            height="5"
            viewBox="0 0 63 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-3.69549e-06 2.53125L63 2.53125"
              stroke="url(#paint0_linear_23657_2131)"
              strokeWidth="4"
              strokeDasharray="8 8"
            />
            <defs>
              <linearGradient
                id="paint0_linear_23657_2131"
                x1="63"
                y1="2.53125"
                x2="47.8689"
                y2="-25.2918"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#666666" />
                <stop offset="1" stopColor="#D7D7D7" />
              </linearGradient>
            </defs>
          </svg>
        )}{" "}
        Natural decay
      </p>
    </div>
  );
};
export default MilaChart;
