import React, { useRef, useEffect, useState } from "react";

const VideoPlayer = ({
  videoSrc,
  poster,
  className = "",
  type = "video/mp4",
}) => {
  const ref = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) return;
    ref?.current?.play();
  }, [isLoaded]);

  return (
    <video
      ref={ref}
      poster={poster}
      muted
      loop
      playsInline
      autoPlay
      className={className}
      height="480"
      width="640"
      onLoadedData={() => setIsLoaded(true)}
    >
      <source src={videoSrc} type={type} />
    </video>
  );
};

export default VideoPlayer;
