/* eslint-disable import/no-unresolved */

import feed1Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-01.mp4';
import feed2Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-02.mp4';
import feed3Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-03.mp4';
import feed4Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-04.mp4';
import feed5Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-05.mp4';
import feed6Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-06.mp4';
import feed7Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-07.mp4';
import feed8Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-08.mp4';
import feed9Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-09.mp4';
import feed10Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-10.mp4';
import feed11Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-11.mp4';
import feed12Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-12.mp4';
import feed13Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-13.mp4';
import feed14Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-14.mp4';
import feed15Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-15.mp4';
import feed16Mp4 from 'assets/images/landingAir/mila-feeds/mila-feed-16.mp4';

import feed1Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-01-thumbnail.jpg';
import feed2Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-02-thumbnail.jpg';
import feed3Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-03-thumbnail.jpg';
import feed4Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-04-thumbnail.jpg';
import feed5Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-05-thumbnail.jpg';
import feed6Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-06-thumbnail.jpg';
import feed7Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-07-thumbnail.jpg';
import feed8Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-08-thumbnail.jpg';
import feed9Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-09-thumbnail.jpg';
import feed10Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-10-thumbnail.jpg';
import feed11Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-11-thumbnail.jpg';
import feed12Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-12-thumbnail.jpg';
import feed13Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-13-thumbnail.jpg';
import feed14Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-14-thumbnail.jpg';
import feed15Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-15-thumbnail.jpg';
import feed16Thumbnail from 'assets/images/landingAir/mila-feeds/mila-feed-16-thumbnail.jpg';

export const feedItems = [
  { videoMp4: feed1Mp4, thumbnail: feed1Thumbnail },
  { videoMp4: feed2Mp4, thumbnail: feed2Thumbnail },
  { videoMp4: feed3Mp4, thumbnail: feed3Thumbnail },
  { videoMp4: feed4Mp4, thumbnail: feed4Thumbnail },
  { videoMp4: feed5Mp4, thumbnail: feed5Thumbnail },
  { videoMp4: feed6Mp4, thumbnail: feed6Thumbnail },
  { videoMp4: feed7Mp4, thumbnail: feed7Thumbnail },
  { videoMp4: feed8Mp4, thumbnail: feed8Thumbnail },
  { videoMp4: feed9Mp4, thumbnail: feed9Thumbnail },
  { videoMp4: feed10Mp4, thumbnail: feed10Thumbnail },
  { videoMp4: feed11Mp4, thumbnail: feed11Thumbnail },
  { videoMp4: feed12Mp4, thumbnail: feed12Thumbnail },
  { videoMp4: feed13Mp4, thumbnail: feed13Thumbnail },
  { videoMp4: feed14Mp4, thumbnail: feed14Thumbnail },
  { videoMp4: feed15Mp4, thumbnail: feed15Thumbnail },
  { videoMp4: feed16Mp4, thumbnail: feed16Thumbnail },
];
