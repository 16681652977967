import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));

import { useIsMobile } from "../../hooks";
import { SUPPORTED_REGIONS } from "../../../shared/enums";

import heroImage1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-01.jpg";
import heroImageWebp1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-01.jpg?as=webp";
import heroImageSm1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-01.jpg";
import heroImageWebpSm1 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-01.jpg?as=webp";

// import heroImage2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-02.jpg";
// import heroImageWebp2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-02.jpg?as=webp";
// import heroImageSm2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-02.jpg";
// import heroImageWebpSm2 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-02.jpg?as=webp";

import heroImage3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-03.jpg";
import heroImageWebp3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-03.jpg?as=webp";
import heroImageSm3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-03.jpg";
import heroImageWebpSm3 from "assets/images/landingAir/hero-christmas/hero-christmas-img-sm-03.jpg?as=webp";

import heroImageNewyear from "assets/images/landingAir/newyear/happy-new-year-2025.jpg";
import heroImageWebpNewyear from "assets/images/landingAir/newyear/happy-new-year-2025.jpg?as=webp";
import heroImageSmNewyear from "assets/images/landingAir/newyear/happy-new-year-2025-sm.jpg";
import heroImageWebpSmNewyear from "assets/images/landingAir/newyear/happy-new-year-2025-sm.jpg?as=webp";

import bestProductImage from "assets/images/landingAir/newyear/mila-with-colors.png";
import bestProductImageWebp from "assets/images/landingAir/newyear/mila-with-colors.png?as=webp";

import milaAward from "assets/images/landingAir/newyear/best-product-award-badge.svg";
import milaAwardSm from "assets/images/landingAir/newyear/best-product-award-badge-sm.svg";

import strongLa from "assets/images/landingAir/newyear/la-strong-bg.jpg";
import strongLaWebp from "assets/images/landingAir/newyear/la-strong-bg.jpg?as=webp";
import strongLaSm from "assets/images/landingAir/newyear/la-strong-bg-sm.jpg";
import strongLaWebpSm from "assets/images/landingAir/newyear/la-strong-bg-sm.jpg?as=webp";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const regionalPrices = {
  US: {
    price: 297,
    oldPrice: "$408-$448",
    description:
      "Breathe in, breathe out with savings of 15% on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be filter.",
  },
  CA: {
    price: 329,
    oldPrice: "$550-$599",
    description:
      "Breathe in, breathe out with savings of up to 30% on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be filter.",
  },
  AU: {
    price: 384,
    oldPrice: "$628-$683",
    description:
      "Breathe in, breathe out with savings of up to 30% on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be filter.",
  },
  SG: {
    price: 333,
    oldPrice: "$549-$604",
    description:
      "Breathe in, breathe out with savings of up to 30% on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be filter.",
  },
  GB: {
    price: null,
    oldPrice: null,
    description:
      "Breathe in, breathe out with savings of up to 30% on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be filter.",
  },
  EU: {
    price: null,
    oldPrice: null,
    description:
      "Breathe in, breathe out with savings of up to 30% on the Mila with Critter Cuddler, Rookie Parent or Mama-to-be filter.",
  },
};

const HeroAirSliderChristmas = ({ localization, handleBuyNow }) => {
  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;
  const { price, oldPrice } = regionalPrices[regional];
  const isMobile = useIsMobile(769);
  const [isSliderInitialized, setIsSliderInitialized] = useState(false);
  const [progress, setProgress] = useState(-100);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleBeforeChange = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    if (!isSliderInitialized) return;
    setProgress(-100);

    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 0 ? -100 : prev + 1));
    }, 60);

    const handleResize = () => {
      setProgress(-100);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", handleResize);
    };
  }, [isSliderInitialized, currentSlide]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    pauseOnFocus: false,
    centerMode: true,
    centerPadding: "35px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "15px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
        },
      },
    ],
    onInit: () => {
      setIsSliderInitialized(true);
    },
    beforeChange: (_, nextIdx) => {
      handleBeforeChange(nextIdx);
    },
  };

  return (
    <section
      className="hero-refresh hero-refresh--newyear"
      style={{ "--progress": `${progress}%` }}
    >
      <div className="container">
        <Slider {...settings} className="hero-refresh__slider">
          {region === SUPPORTED_REGIONS.US && <LAStrong />}
          {(region === SUPPORTED_REGIONS.US ||
            region === SUPPORTED_REGIONS.CA) && (
              <BestProduct
                isMobile={isMobile}
                region={region}
                regional={regional}
              />
            )}
          <FirstSlide isMobile={isMobile} regional={regional} />
          <NewYear handleBuyNow={handleBuyNow} isMobile={isMobile} />
          {/* <CelebrateWithMila isMobile={isMobile} handleBuyNow={handleBuyNow} /> */}
          {/* <ShrinkSlide /> */}
        </Slider>
      </div>
    </section>
  );
};

export default HeroAirSliderChristmas;

function NewYear({ handleBuyNow, isMobile }) {
  return (
    <div className="hero-refresh__item hero-item hero-item--04">
      <ImageLoader
        className="hero-item__image img-relative"
        placeholderAspectRatio={1320 / 680}
        image={heroImageNewyear}
        webp={heroImageWebpNewyear}
        smallImage={heroImageSmNewyear}
        smallWebp={heroImageWebpSmNewyear}
        alt="New Year 2025"
        noLazyLoad
      />

      <div className="hero-item__body">
        <div className="hero-item__content">
          <strong className="hero-item__subtitle">
            {isMobile ? "Now in 2 sizes" : "CELEBRATE IN 2 SIZES"}
          </strong>
          <h1 className="hero-item__title">
            {isMobile ? (
              "New year, new air."
            ) : (
              <>
                Now in 2 sizes,
                <br /> and 5 colors.
              </>
            )}
          </h1>
          <p className="hero-item__description">
            Fresh air for the new year. Mila Air and Air Mini bring powerful
            purification to every space, big or small.
          </p>
        </div>
        <button
          type="button"
          className="hero-item__button button button--white"
          onClick={(e) => handleBuyNow(e)}
        >
          Buy Now
        </button>
      </div>
    </div>
  );
}

function BestProduct({ isMobile, regional }) {
  const data = {
    [SUPPORTED_REGIONS.US]: {
      badge: {
        desktop: (
          <>
            <strong>Preorder now</strong> <br />
            for February <br />
            delivery <br />
            🚀
          </>
        ),
        mobile: (
          <>
            <strong>Preorder now</strong>
            <br />
            for February <br />
            delivery
          </>
        ),
      },
      title: "The new Air Mini sold out in 3 weeks.",
      description:
        "Mini and mighty. The newest member of the Mila family packs a punch in five homey hues.",
      button: {
        text: {
          desktop: "Preorder Now",
          mobile: "Buy Now",
        },
        link: "https://airmini.milacares.com",
      },
    },
    [SUPPORTED_REGIONS.CA]: {
      badge: {
        desktop: (
          <>
            <strong>Free shipping</strong> <br />
            in 3-7 days <br />
            🚀
          </>
        ),
        mobile: (
          <>
            <strong>In stock</strong>
            <br />
            Get yours now!
          </>
        ),
      },
      title: (
        <>
          Say hello to <br /> Mila Air Mini.
        </>
      ),
      description:
        "Mini and mighty. The newest member of the Mila family packs a punch in five home-worthy hues.",
      button: {
        text: {
          desktop: "Order Now",
          mobile: "Order Now",
        },
        link: "https://airmini.milacares.com",
      },
    },
    [SUPPORTED_REGIONS.AU]: {
      badge: {
        desktop: (
          <>
            <strong>Free shipping</strong> <br />
            in 3-7 days <br />
            🚀
          </>
        ),
        mobile: (
          <>
            <strong>In stock</strong>
            <br />
            Get yours now!
          </>
        ),
      },
      title: (
        <>
          Say hello to <br /> Mila Air Mini.
        </>
      ),
      description:
        "Mini and mighty. The newest member of the Mila family packs a punch in five home-worthy hues.",
      button: {
        text: {
          desktop: "Order Now",
          mobile: "Order Now",
        },
        link: "https://airmini.milacares.com",
      },
    },
    [SUPPORTED_REGIONS.SG]: {
      badge: {
        desktop: (
          <>
            <strong>Free shipping</strong> <br />
            in 3-7 days <br />
            🚀
          </>
        ),
        mobile: (
          <>
            <strong>In stock</strong>
            <br />
            Get yours now!
          </>
        ),
      },
      title: (
        <>
          Say hello to <br /> Mila Air Mini.
        </>
      ),
      description:
        "Mini and mighty. The newest member of the Mila family packs a punch in five home-worthy hues.",
      button: {
        text: {
          desktop: "Order Now",
          mobile: "Order Now",
        },
        link: "https://airmini.milacares.com",
      },
    },
    [SUPPORTED_REGIONS.GB]: {
      badge: {
        desktop: (
          <>
            <strong>Free shipping</strong> <br />
            in 3-7 days <br />
            🚀
          </>
        ),
        mobile: (
          <>
            <strong>In stock</strong>
            <br />
            Get yours now!
          </>
        ),
      },
      title: (
        <>
          Say hello to <br /> Mila Air Mini.
        </>
      ),
      description:
        "Mini and mighty. The newest member of the Mila family packs a punch in five home-worthy hues.",
      button: {
        text: {
          desktop: "Order Now",
          mobile: "Order Now",
        },
        link: "https://airmini.milacares.com",
      },
    },
    [SUPPORTED_REGIONS.EU]: {
      badge: {
        desktop: (
          <>
            <strong>Free shipping</strong> <br />
            in 3-7 days <br />
            🚀
          </>
        ),
        mobile: (
          <>
            <strong>In stock</strong>
            <br />
            Get yours now!
          </>
        ),
      },
      title: (
        <>
          Say hello to <br /> Mila Air Mini.
        </>
      ),
      description:
        "Mini and mighty. The newest member of the Mila family packs a punch in five home-worthy hues.",
      button: {
        text: {
          desktop: "Order Now",
          mobile: "Order Now",
        },
        link: "https://airmini.milacares.com",
      },
    },
  };
  const { badge, title, description, button } = data[regional];

  return (
    <div className="hero-item hero-item--best-product">
      <div className="hero-item__text-badge">
        <p>{badge[isMobile ? "mobile" : "desktop"]}</p>
      </div>
      {isMobile && <Award />}
      <ImageLoader
        className="hero-item__image img-relative"
        placeholderAspectRatio={956 / 264}
        image={bestProductImage}
        webp={bestProductImageWebp}
        alt="mila best sell product 2025 - mila cares"
        noLazyLoad
      />
      <div className="hero-item__body">
        {!isMobile && <Award />}
        <div className="hero-item__content">
          {isMobile && <MilaColors />}
          <h1 className="hero-item__title">{title}</h1>
          <p className="hero-item__description">{description}</p>
          {!isMobile && <MilaColors />}
        </div>
        <a
          href={button.link}
          className="hero-item__button button button--black"
        >
          {button.text[isMobile ? "mobile" : "desktop"]}
        </a>
      </div>
    </div>
  );
}

function Award() {
  return (
    <figure className="hero-item__award">
      <picture>
        <source
          srcSet={milaAwardSm}
          media="(max-width: 768px)"
          type="image/svg+xml"
        />
        <img src={milaAward} alt="mila best sell product 2025 - mila cares" />
      </picture>
    </figure>
  );
}

function MilaColors() {
  return (
    <ul className="hero-item__color-list">
      <li style={{ backgroundColor: "#FFFFFF" }}>
        <span className="screen-reader-text">mila white</span>
      </li>
      <li style={{ backgroundColor: "#9A9489" }}>
        <span className="screen-reader-text">mila gray</span>
      </li>
      <li style={{ backgroundColor: "#747375" }}>
        <span className="screen-reader-text">mila sand gray</span>
      </li>
      <li style={{ backgroundColor: "#3B4753" }}>
        <span className="screen-reader-text">mila dark gray</span>
      </li>
      <li style={{ backgroundColor: "#48554D" }}>
        <span className="screen-reader-text">mila dark green</span>
      </li>
    </ul>
  );
}

function LAStrong() {
  return (
    <div className="hero-item hero-item--la-strong">
      <ImageLoader
        className="hero-item__image img-relative"
        placeholderAspectRatio={1320 / 680}
        image={strongLa}
        webp={strongLaWebp}
        smallImage={strongLaSm}
        smallWebp={strongLaWebpSm}
        alt="LA Strong"
        noLazyLoad
      />
      <div className="hero-item__content">
        <h1 className="hero-item__title">
          Mila<span></span>LA
        </h1>
        <br />
        <strong className="hero-item__tag">#LAstrong</strong>
        <div className="hero-item__description">
          <p>
            Our hearts go out to the millions of Angelenos impacted by these
            historic wildfires. Learn how you can care for your air with these
            resources.
          </p>
        </div>
        <a
          href="https://bit.ly/lastrong"
          className="hero-item__button button button--white"
        >
          Learn More
        </a>
      </div>
    </div>
  );
}

// function ShrinkSlide() {
//   return (
//     <div className="hero-refresh__item hero-item hero-item--03">
//       <ImageLoader
//         className="hero-item__image img-relative"
//         placeholderAspectRatio={1320 / 617}
//         image={heroImage3}
//         webp={heroImageWebp3}
//         smallImage={heroImageSm3}
//         smallWebp={heroImageWebpSm3}
//         alt="The Holiday Sale"
//         noLazyLoad
//       />

//       <div className="hero-item__body">
//         <div className="hero-item__content">
//           <strong className="hero-item__subtitle">NOW IN 2 SIZES</strong>
//           <h1 className="hero-item__title">
//             Honey, we <br />
//             shrunk the Mila.
//           </h1>
//           <p className="hero-item__description">
//             Introducing Mila Air Mini: Big power for your smaller spaces—so no
//             room gets left behind.
//           </p>
//         </div>
//         <a
//           href="https://airmini.milacares.com"
//           className="hero-item__button button button--white"
//         >
//           Buy Now
//         </a>
//       </div>
//     </div>
//   );
// }

// function CelebrateWithMila({ isMobile, handleBuyNow }) {
//   return (
//     <div className="hero-refresh__item hero-item hero-item--02">
//       <ImageLoader
//         className="hero-item__image img-relative"
//         placeholderAspectRatio={1320 / 617}
//         image={heroImage2}
//         webp={heroImageWebp2}
//         smallImage={heroImageSm2}
//         smallWebp={heroImageWebpSm2}
//         alt="The Holiday Sale"
//         noLazyLoad
//       />

//       <div className="hero-item__body">
//         <div className="hero-item__content">
//           <strong className="hero-item__subtitle">CELEBRATE WITH MILA</strong>
//           <h1 className="hero-item__title">Joy to air.</h1>
//           <p className="hero-item__description">
//             {isMobile
//               ? "Give and get the gift that’s always in season with the award-winning air purifiers on everyone’s list. "
//               : "Give and get the gift that’s always in season, and celebrate with the award-winning air purifiers on everyone’s list."}
//           </p>
//         </div>
//         <button
//           onClick={(e) => handleBuyNow(e)}
//           className="hero-item__button button button--white"
//         >
//           Buy Now
//         </button>
//       </div>
//     </div>
//   );
// }

function FirstSlide({ isMobile, regional }) {
  const { price, oldPrice } = regionalPrices[regional];
  return (
    <div className="hero-refresh__item hero-item">
      <ImageLoader
        className="hero-item__image img-relative"
        placeholderAspectRatio={1320 / 617}
        image={heroImage1}
        webp={heroImageWebp1}
        smallImage={heroImageSm1}
        smallWebp={heroImageWebpSm1}
        alt="The Holiday Sale"
        noLazyLoad
      />
      {price && (
        <strong className="hero-item__badge">
          <span>Starting at</span>
          <strong>${price}</strong>
          <del>{oldPrice}</del>
        </strong>
      )}
      <div className="hero-item__body">
        <div className="hero-item__content">
          <strong className="hero-item__subtitle">
            {isMobile ? "THE NEW YEAR SALE" : "A MILA FOR EVERY AIR BREATHER"}
          </strong>
          <h1 className="hero-item__title">
            {isMobile ? (
              <>
                A Mila for every <br /> air breather.
              </>
            ) : (
              "The New Year Sale."
            )}
          </h1>
          <p className="hero-item__description">
            {regionalPrices[regional].description}
          </p>
        </div>
        <a
          href="https://shop.milacares.com"
          className="hero-item__button button button--white"
        >
          Buy Now
        </a>
      </div>
    </div>
  );
}
